import { Colors } from 'app/util/colors';
import { ChangeEvent, useId } from 'react';

import styles from './Switch.module.scss';

interface SwitchProps {
    name: string;
    checked: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Switch = ({ name, checked, onChange }: SwitchProps) => {
    const id = useId();

    return (
        <>
            <input
                className={styles['react-switch-checkbox']}
                id={id}
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <label
                className={styles['react-switch-label']}
                style={{ backgroundColor: checked ? Colors['color-primary'] : 'grey' }}
                htmlFor={id}
                aria-label={`Toggle switch for ${name}`} // Accessible text added here
            >
                <span className={styles[`react-switch-button`]} />
            </label>
        </>
    );
};
