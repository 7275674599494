import { Favorite, MetricsTrade, Wallet } from 'app/models';
import { TokenLogos } from 'app/store/slices';
import { PlotResponse, RouterResponse } from './responses';
import { lazy, number, object, string } from 'yup';
import { Colors } from 'app/util/colors';
import Plotly from 'plotly.js-dist-min';
import { SubscriptionType, CognitoGroup } from './interfaces';

export function setPageTitle(title?: string) {
  document.title = title ? `${title} - Wallet Market Cap` : 'Wallet Market Cap';
}

export function trimWalletAddress(address: string): string {
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
}

export function getMetricTrades(
  wallet: Wallet,
  type: 'Winner' | 'Loser' | 'W/L'
): MetricsTrade[] {
  const key =
    type === 'Winner'
      ? 'WINNER'
      : type === 'Loser'
      ? 'LOOSER'
      : 'WINNER_AND_LOOSER';
  const trades: MetricsTrade[] = [];

  for (
    let i = 0;
    i < wallet[`${key}_TRADES_tok1_symb_PER_TOKEN_list`].length;
    ++i
  ) {
    const trade: MetricsTrade = {
      numTrades: getNumTrades(wallet, i, key),
      tokenSymbol: wallet[`${key}_TRADES_tok1_symb_PER_TOKEN_list`][i],
      profitFraction: getProfitFraction(wallet, i, key),
      tokenContract: wallet[`${key}_TRADES_token1_contract_PER_TOKEN_list`][i],
      roiPercent: {
        max: wallet[`${key}_TRADES_sell_ROI_for_trade_PER_TOKEN_max`][i],
        min: wallet[`${key}_TRADES_sell_ROI_for_trade_PER_TOKEN_min`][i],
        mean: wallet[`${key}_TRADES_sell_ROI_for_trade_PER_TOKEN_mean`][i],
        sum: wallet[`${key}_TRADES_sell_ROI_for_trade_PER_TOKEN_max`][i],
      },
      roiUsd: {
        max:
          key === 'WINNER_AND_LOOSER'
            ? wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_max`][i]
            : wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_max`][
                i
              ],
        min:
          key === 'WINNER_AND_LOOSER'
            ? wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_min`][i]
            : wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_min`][
                i
              ],
        mean:
          key === 'WINNER_AND_LOOSER'
            ? wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_mean`][
                i
              ]
            : wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_mean`][
                i
              ],
        sum:
          key === 'WINNER_AND_LOOSER'
            ? wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_max`][i]
            : wallet[`${key}_TRADES_sell_profit_USD_for_trade_PER_TOKEN_max`][
                i
              ],
      },
    };
    trades.push(trade);
  }

  return trades;
}

function getNumTrades(
  wallet: Wallet,
  index: number,
  key: 'WINNER' | 'LOOSER' | 'WINNER_AND_LOOSER'
) {
  return key === 'WINNER'
    ? wallet.WINNER_TRADES_sell_ROI_for_trade_PER_TOKEN_mean.length
    : key === 'LOOSER'
    ? wallet.LOOSER_TRADES_sell_ROI_for_trade_PER_TOKEN_mean.length
    : wallet.WINNER_AND_LOOSER_TRADES_sell_ROI_for_trade_PER_TOKEN_mean.length;
}

function getProfitFraction(
  wallet: Wallet,
  index: number,
  key: 'WINNER' | 'LOOSER' | 'WINNER_AND_LOOSER'
) {
  return key === 'WINNER'
    ? wallet.WINNER_TRADES_total_profit_in_USD_fraction_PER_TOKEN[index]
    : key === 'LOOSER'
    ? wallet.LOOSER_TRADES_total_loss_in_USD_fraction_PER_TOKEN[index]
    : wallet.WINNER_AND_LOOSER_TRADES_total_profit_in_USD_fraction_PER_TOKEN[
        index
      ];
}

export function truncateNumber(
  value: number | string,
  decimalDigits = 2
): string | null {
  const TRILLION = 1000000000000;
  const BILLION = 1000000000;
  const MILLION = 1000000;
  const THOUSAND = 1000;

  let num = value;
  if (typeof value === 'number') {
    num = value;
  } else {
    try {
      num = Number.parseFloat(value);
    } catch (error) {
      return null;
    }
  }

  if (Math.abs(num) / TRILLION > 1) {
    return `${(num / TRILLION).toFixed(decimalDigits)}T`;
  }
  if (Math.abs(num) / BILLION > 1) {
    return `${(num / BILLION).toFixed(decimalDigits)}B`;
  }
  if (Math.abs(num) / MILLION > 1) {
    return `${(num / MILLION).toFixed(decimalDigits)}M`;
  }
  if (Math.abs(num) / THOUSAND > 10) {
    return `${(num / THOUSAND).toFixed(decimalDigits)}K`;
  }

  return num.toFixed(decimalDigits);
}

export function getRelativeTime(
  firstDate: Date,
  secondDate = new Date()
): string {
  const units: { [key: string]: number } = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const difference = firstDate.getTime() - secondDate.getTime();

  for (const unit in units)
    if (Math.abs(difference) > units[unit] || unit === 'second')
      // @ts-ignore TypeScript will not let sending 'unit' as the second argument
      return rtf.format(Math.round(difference / units[unit]), unit);

  return '';
}

export function getContractsWithoutLogos(
  contracts: string[],
  logos: TokenLogos
): string[] {
  return removeDuplicates(contracts).filter((contract) => !logos[contract]);
}
export function getContractsWithoutRouters(
  contracts: string[],
  routers: { [key: string]: RouterResponse }
): string[] {
  return removeDuplicates(contracts).filter((contract) => !routers[contract]);
}

export function removeDuplicates<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export async function copyToClipboard(text: string): Promise<void> {
  await navigator.clipboard.writeText(text);
  return;
}

export function getEtherscanTransactionLink(transactionHash: string): string {
  return `https://etherscan.io/tx/${transactionHash}`;
}

export function getPlotConfig(): Partial<Plotly.Config> {
  return {
    displayModeBar: true,
    displaylogo: false,
    modeBarButtonsToRemove: [
        'lasso2d',
        'select2d',
        'sendDataToCloud',
        'zoom2d',
        'pan2d',
        'zoomIn2d',
        'zoomOut2d',
        'autoScale2d',
        'resetScale2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'zoom3d',
        'pan3d',
        'orbitRotation',
        'tableRotation',
        'resetCameraDefault3d',
        'resetCameraLastSave3d',
        'hoverClosest3d',
        'zoomInGeo',
        'zoomOutGeo',
        'resetGeo',
        'hoverClosestGeo',
        'hoverClosestGl2d',
        'hoverClosestPie',
        'toggleHover',
        'toImage',
        'resetViews',
        'toggleSpikelines',
        'togglespikelines',
        'togglehover',
        'hovercompare',
        'hoverclosest',
        'v1hovermode',
        'resetViews',
  ],

  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFromLocalStorage(key: string): any {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

export function setToLocalStorage(key: string, value: unknown): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string): void {
  localStorage.removeItem(key);
}

export function getPlotLayout(): Partial<Plotly.Layout> {
  return {
    font: {
      family: 'SF Pro Display Regular',
      size: 14,
      color: Colors['color-light-0'],
    },
    paper_bgcolor: Colors['color-dark-1'],
    plot_bgcolor: Colors['color-dark-1'],
    showlegend: false,
    title: '',
  };
}

export function getPlotTitle(plot: PlotResponse): string {
  // @ts-ignore Needed to ignore type issues
  return plot.layout?.title?.text;
}

export const schema = object().shape({
  min_profit: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  min_volume: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  min_trades: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  min_avg_invest_size: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  min_win_loss_ratio: lazy((value) =>
    value === '' ? string() : number().min(0).max(1)
  ),
  max_profit: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  max_volume: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  max_trades: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  max_avg_invest_size: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  min_avg_roi_per_trade: lazy((value) =>
    value === '' ? string() : number().min(0).max(1)
  ),
  min_swap_profit: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  swap_min_size: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  max_swap_profit: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  swap_max_size: lazy((value) =>
    value === '' ? string() : number().min(1).max(10000000)
  ),
  starting_balance: lazy((value) =>
    value === '' ? string() : number().min(100).max(9999999999999)
  ),
  invest_size: lazy((value) =>
    value === '' ? string() : number().min(1).max(9999999999999)
  ),
  avg_price_miss_perc: lazy((value) =>
    value === '' ? string() : number().min(1).max(9999999999999)
  ),
  // collection_id: string().matches(/^(Not selected.+|(?!Not selected).*)$/),
});
export const simulatorSchema = object().shape({
  starting_balance: lazy((value) =>
    value === '' ? string().min(1) : number().min(100).max(9999999999999)
  ),
  invest_size: lazy((value) =>
    value === '' ? string().min(1) : number().min(1).max(9999999999999)
  ),
  avg_price_miss_perc: lazy((value) =>
    value === '' ? string().min(1) : number().min(1).max(9999999999999)
  ),
  collection_id: string().matches(/^(Not selected.+|(?!Not selected).*)$/),
});
export const getNotEmptyFavorites = (favorites: Favorite[]) => {
  const notEmptyFavorites = favorites.filter(
    (favorite) => favorite.collection_items.length
  );
  return notEmptyFavorites;
};

export const defineSubscription = (
  cognitoGroups: CognitoGroup[] | undefined
): SubscriptionType => {
  if (!cognitoGroups) {
    return null;
  }
  // Don't change order
  if (cognitoGroups.includes('WMC-BETA-elite-sub')) {
    return 'Elite';
  }
  if (cognitoGroups.includes('WMC-BETA-advanced-sub')) {
    return 'Advanced';
  }
  if (cognitoGroups.includes('WMC-BETA-premium-sub')) {
    return 'Premium';
  }
  if (cognitoGroups.includes('WMC-BETA-basic-sub')) {
    return 'Basic';
  }
  if (cognitoGroups.includes('WMC-BETA-trial-sub')) {
    return 'Trial';
  }
  if (cognitoGroups.includes('WMC_BETA_sub_ran_out')) {
    return 'Expired';
  }
  return null;
};

export const convertMonthIndexToName = (monthIndex: number): string => {
  const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[monthIndex];
};

export const calcTwoDatesDayDifference = (
  expireDate: number,
  date: number
): number => {
  if (expireDate - date <= 0) {
    return 0;
  }
  const oneDay = 24 * 60 * 60 * 1000;
  const leftDays = Math.ceil((expireDate - date) / oneDay);
  return leftDays;
};

export const extractNumberFromString = (str: string): number => {
  const notDigitRegExr = /\D/g;
  const res = str.replace(notDigitRegExr, '');

  return Number(res);
};

interface WalletTypes {
  is_mev_bot: boolean;
  is_high_freq_bot: boolean;
  is_flash_botter: boolean;
  is_top_trader: boolean;
  is_arbitrageur: boolean;
  is_sandwicher: boolean;
  is_sandwich_victim: boolean;
  is_human: boolean;
}

export const formatWalletTypes = (walletTypes: WalletTypes): string => {
  const res: string[] = [];
  if (walletTypes.is_mev_bot) {
    res.push('🤖');
  }
  if (walletTypes.is_high_freq_bot) {
    res.push('🏃');
  }
  if (walletTypes.is_flash_botter) {
    res.push('⚡');
  }
  if (walletTypes.is_top_trader) {
    res.push('🧠');
  }
  if (walletTypes.is_arbitrageur) {
    res.push('💱');
  }
  if (walletTypes.is_sandwicher) {
    res.push('🥪');
  }
  if (walletTypes.is_sandwich_victim) {
    res.push('😵');
  }
  if (walletTypes.is_human) {
    res.push('👨');
  }
  return res.join(', ');
};

export const isFavorite = (wallet: string, favorites: Favorite[]) => {
  return favorites.some(({ collection_items }) =>
    collection_items.includes(wallet)
  );
};

type StringValuesObj = {
  [key: string]: string;
};

export const getObjectFromSearchParams = (
  searchParams: URLSearchParams
): StringValuesObj => {
  const result: StringValuesObj = {};
  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const removeSnapshotFromSearchParams = (
  searchParams: URLSearchParams
): StringValuesObj => {
  const result: StringValuesObj = {};
  searchParams.forEach((value, key) => {
    if (key === 'snapshot') {
      return;
    }

    result[key] = value;
  });

  return result;
};
