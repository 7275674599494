import { truncateNumber } from 'app/util/helper-functions';
import styles from './SellToken.module.scss';



interface SellTokenProps {
    logo: string;
    value: number;
    roiClass?: string;
}

const SellToken = ({ logo, value, roiClass }: SellTokenProps): JSX.Element => {
    return (
        <div className={styles['sell-token']}>
            <div className={styles['sell-token-content']}>
                <img className={styles['sell-token-image']} src={logo} alt="logo" />
                <span className={`${styles['sell-token-value']} ${roiClass || ''}`}>
          ${truncateNumber(value)}
        </span>
            </div>
        </div>
    );
};


export default SellToken;
