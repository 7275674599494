import { truncateNumber } from 'app/util/helper-functions';
import { IconChevron } from 'app/components';
import styles from './BestSellTD.module.scss';
import SellToken from './SellToken/SellToken';


interface ProfitProps {
  roiPercent: number;
  roiUsd: number;
}



interface TokenProps {
    tokenALogo: string;
    tokenAUsdPrice: number;
    tokenBLogo: string;
    tokenBUsdPrice: number;
    roiPercent: number;
    roiUsd: number;
}
const BestSellTDTokens = ({
                        tokenALogo,
                        tokenAUsdPrice,
                        tokenBLogo,
                        tokenBUsdPrice,
                        roiPercent,
                    }: TokenProps): JSX.Element => {
    const isRoiPositive = roiPercent > 0;
    const roiPercentClass = isRoiPositive ? 'color-green' : 'color-red';
    return (
        <div className={styles.sell}>

            <SellToken value={tokenAUsdPrice} logo={tokenALogo} />
            <IconChevron />
            <SellToken value={tokenBUsdPrice} logo={tokenBLogo} roiClass={roiPercentClass} />
        </div>
    );
};




const BestSellTDProfit = ({
                              roiPercent,
                              roiUsd,
                          }: ProfitProps): JSX.Element => {
    const isRoiPositive = roiPercent > 0;
    const roiPercentClass = isRoiPositive ? 'color-green' : 'color-red';

    return (
        <div className={styles.sell}>
            <div className={`${roiPercentClass} ${styles['roi-values']}`}>
                {isRoiPositive && '+'}
                {truncateNumber(roiPercent)}%
            </div>
            <span className={styles.separator}>|</span>
            <div className={` ${roiPercentClass} ${styles.roi} ${styles['roi-values']}`}>
                ${truncateNumber(roiUsd)}
            </div>
        </div>
    );
};




export { BestSellTDTokens,BestSellTDProfit };
