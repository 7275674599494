import wmcLogo from 'assets/logos/gold-logo.svg';
import styles from './Header.module.scss';
import {
  IconButton,
  IconMenu,
  SearchInput,
  IconSettings,
} from 'app/components';
import { Menu } from './Menu';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectActiveMetadata, setIsMenuOpen } from 'app/store/slices';
import { MetadataBox } from './MetadataBox/MetadataBox';
import { setMode,selectMode  } from 'app/store/slices/mode.slice';
import { useSelector } from 'react-redux';

export const Header: FC = () => {
    const dispatch = useAppDispatch();
    const activeMetadata = useAppSelector(selectActiveMetadata);
    const currentMode = useSelector(selectMode);

    const openMenu = () => {
        dispatch(setIsMenuOpen(true));
    };

    const handleModeChange = (newMode: 'SIMPLE' | 'PRO') => {
        if (newMode !== currentMode) {
            dispatch(setMode(newMode));
        }
    };

    return (
        <header className={styles.header}>
            <Menu />
            <div className={styles.col}>
                <IconButton onClick={openMenu}>
                    <IconMenu />
                </IconButton>
                <h2 className={styles.logo}>
                    <Link
                        to={
                            activeMetadata
                                ? `/wallets?snapshot=${activeMetadata.snapshot_id}`
                                : '/wallets'
                        }
                    >
                        <img
                            src={wmcLogo}
                            width={47}
                            height={47}
                            alt="Wallet Market Cap logo"
                        />
                        <span className={styles['logo-text']}>Wallet Market Cap</span>
                    </Link>
                </h2>
            </div>

            {/* Mode Toggle with Sliding Animation */}
            <div
                className={`${styles['mode-toggle']} ${
                    currentMode === 'SIMPLE' ? styles['simple-mode'] : styles['pro-mode']
                }`}
            >
                <button
                    className={currentMode === 'SIMPLE' ? styles.active : ''}
                    onClick={() => handleModeChange('SIMPLE')}
                >
                    Simple Mode
                </button>
                <button
                    className={currentMode === 'PRO' ? styles.active : ''}
                    onClick={() => handleModeChange('PRO')}
                >
                    Pro Mode
                </button>
            </div>

            <div className={styles.col}>
                <MetadataBox />
            </div>
            <div className={styles.col}>
                <SearchInput placeholder="Search wallet address/Token Contract" />
                <div className={styles['settings-box']}>
                    <Link to="/settings">
                        <IconSettings />
                    </Link>
                </div>
            </div>
        </header>
    );
};
