import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangePasswordRequest,
  ConfirmSignupRequest,
  ForgotPasswordRequest,
  LoginRequest,
  ResendConfirmationCodeRequest,
  ResetPasswordRequest,
  SignupRequest,

} from 'app/util/requests';
import {
    changeUserPassword,
    confirmSignupUser,
    forgotUserPassword,
    loginUser,// loginUserWithWallet,
    refreshToken,
    resendConfirmEmailCode,
    resetUserPassword,
    signupUser,
} from 'app/api';
// import {WalletLoginRequest} from "../../util/requests/wallet-login";

enum AuthThunks {
  LOGIN = 'auth/login',
  SIGNUP = 'auth/signup',
  RESEND_CONFIRMATION_CODE = 'auth/resendConfirmationCode',
  CONFIRM_SIGNUP = 'auth/confirmSignup',
  FORGOT_PASSWORD = 'auth/forgotPassword',
  RESET_PASSWORD = 'auth/resetPassword',
  CHANGE_PASSWORD = 'auth/changePassword',
  REFRESH_ACCESS_TOKEN = 'auth/refreshAccessToken',
  // LOGIN_WALLET = 'auth/loginWallet',
}

export const login = createAsyncThunk(
  AuthThunks.LOGIN,
  (payload: LoginRequest) => {
    return loginUser(payload);
  }
);
//
// export const loginWallet = createAsyncThunk(
//     AuthThunks.LOGIN_WALLET,
//     (payload: WalletLoginRequest) => {
//         return loginUserWithWallet(payload);
//     }
// );


export const signup = createAsyncThunk(
  AuthThunks.SIGNUP,
  (payload: SignupRequest) => {
    return signupUser(payload);
  }
);

export const resendConfirmationCode = createAsyncThunk(
  AuthThunks.RESEND_CONFIRMATION_CODE,
  (payload: ResendConfirmationCodeRequest) => {
    return resendConfirmEmailCode(payload);
  }
);

export const confirmSignup = createAsyncThunk(
  AuthThunks.CONFIRM_SIGNUP,
  (payload: ConfirmSignupRequest) => {
    return confirmSignupUser(payload);
  }
);

export const forgotPassword = createAsyncThunk(
  AuthThunks.FORGOT_PASSWORD,
  (payload: ForgotPasswordRequest) => {
    return forgotUserPassword(payload);
  }
);

export const resetPassword = createAsyncThunk(
  AuthThunks.RESET_PASSWORD,
  (payload: ResetPasswordRequest) => {
    return resetUserPassword(payload);
  }
);

export const changePassword = createAsyncThunk(
  AuthThunks.CHANGE_PASSWORD,
  (payload: ChangePasswordRequest) => {
    return changeUserPassword(payload);
  }
);

export const refreshAccessToken = createAsyncThunk(
  AuthThunks.REFRESH_ACCESS_TOKEN,
  () => {
    return refreshToken();
  }
);
