import { useFormContext } from 'react-hook-form';

import { QuadStateCheckbox, Tooltip } from 'app/components';
import styles from './WalletTypes.module.scss';
import { useAppSelector } from 'app/store';
import { selectCredits, selectWalletFilters } from 'app/store/slices';

type Checkbox = {
  label: string;
  name: string;
  tooltipText: string;
  isReachable: boolean;
  defaultValue?: 'include' | 'exclude' | 'any' | 'exclude_not_authorized';
};

const WalletTypes = (): JSX.Element => {
  const methods = useFormContext();
  const {
    can_access_mev_bot_filter,
    can_access_flash_bot_filter,
    can_access_is_top_trader_filter,
    can_access_high_freq_bot_filter,
    can_access_is_arbitrageur_filter,
    can_access_is_sandwicher_filter,
    can_access_is_human_filter,
  } = useAppSelector(selectCredits);
  const {
    is_mev_bot,
    is_flash_bot,
    is_top_trader,
    is_high_freq_bot,
    is_arbitrageur,
    is_sandwicher,
    is_human,
  } = useAppSelector(selectWalletFilters);

  const quadStateCheckboxes: Checkbox[] = [
    {
      label: 'Mev Bot',
      name: 'is_mev_bot',
      tooltipText: 'MEV bots use MEV proxy contracts 🤖',
      isReachable: can_access_mev_bot_filter,
      defaultValue: can_access_mev_bot_filter
        ? is_mev_bot
        : 'exclude_not_authorized',
    },
    {
      label: 'Flash Botter',
      name: 'is_flash_botter',
      tooltipText: 'Flash bots use Flash Contracts and are marked with  ⚡ ',
      isReachable: can_access_flash_bot_filter,
      defaultValue: can_access_flash_bot_filter
        ? is_flash_bot
        : 'exclude_not_authorized',
    },
    {
      label: 'Top Trader',
      name: 'is_top_trader',
      tooltipText:
        'Top Traders are in the top 100 most profitable wallets of any category 🧠',
      isReachable: can_access_is_top_trader_filter,
      defaultValue: can_access_is_top_trader_filter
        ? is_top_trader
        : 'any',
    },
    {
      label: 'HF Bot',
      name: 'is_high_freq_bot',
      tooltipText: 'High Frequency bots perform many trades per hour 🏃',
      isReachable: can_access_high_freq_bot_filter,
      defaultValue: can_access_high_freq_bot_filter
        ? is_high_freq_bot
        : 'exclude_not_authorized',
    },
    {
      label: 'Arbitrageur',
      name: 'is_arbitrageur',
      tooltipText: 'Arbitrageurs are wallets that perform arbitrage trades 💱',
      isReachable: can_access_is_arbitrageur_filter,
      defaultValue: can_access_is_arbitrageur_filter
        ? is_arbitrageur
        : 'exclude_not_authorized',
    },
    {
      label: 'Sandwicher',
      name: 'is_sandwicher',
      tooltipText: 'Sandwichers are wallets that perform sandwich attacks 🥪',
      isReachable: can_access_is_sandwicher_filter,
      defaultValue: can_access_is_sandwicher_filter
        ? is_sandwicher
        : 'exclude_not_authorized',
    },
    {
      label: 'Human',
      name: 'is_human',
      tooltipText: 'Humans are wallets that are not bots',
      isReachable: can_access_is_human_filter,
      defaultValue: can_access_is_human_filter
        ? is_human
        : 'any',
    },
    { label: 'Any', name: 'any', tooltipText: 'Any', isReachable: true },
  ];

  return (
    <div className={styles['wallet-types-wrapper']}>
      <div className={styles['header']}>
        <span>Wallet Types</span>
        <Tooltip
          size="medium"
          position="bottom-right"
          text="Use the Check Mark symbol to Include a set of wallets. Use the X symbol to exclude them and leave the box grey iif you don't care. "
        />
      </div>
      <div className={styles['wallet-types-container']}>
        {quadStateCheckboxes.map((quadStateCheckbox: Checkbox, i) => (
          <div className={styles['input-wrapper']} key={i}>
            <QuadStateCheckbox {...quadStateCheckbox} {...methods} />
          </div>
        ))}
      </div>
    </div>
  );
};

export { WalletTypes };
