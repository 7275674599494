import React from 'react';
import styles from './WalletTypesRender.module.scss';

interface WalletTypes {
    is_mev_bot: boolean;
    is_high_freq_bot: boolean;
    is_flash_botter: boolean;
    is_top_trader: boolean;
    is_arbitrageur: boolean;
    is_sandwicher: boolean;
    is_sandwich_victim: boolean;
    is_human?: boolean;
}

interface WalletTypesRenderProps {
    walletTypes: WalletTypes;
    layout?: 'center' | 'left'; // New layout prop
}

export const WalletTypesRender = ({ walletTypes, layout = 'center' }: WalletTypesRenderProps): JSX.Element => {
    const labels: { [key: string]: { emoji: string; label: string } } = {
        is_mev_bot: { emoji: '🤖', label: 'MEV bots use MEV proxy contracts 🤖' },
        is_high_freq_bot: { emoji: '🏃', label: 'High Frequency bots perform many trades per hour 🏃' },
        is_flash_botter: { emoji: '⚡', label: 'Flash bots use Flash Contracts and are marked with  ⚡' },
        is_top_trader: { emoji: '🧠', label: 'Top Traders are in the top 100 most profitable wallets of any category 🧠' },
        is_arbitrageur: { emoji: '💱', label: 'Arbitrageurs are wallets that perform arbitrage trades 💱' },
        is_sandwicher: { emoji: '🥪', label: 'Sandwichers are wallets that perform sandwich attacks 🥪' },
        is_sandwich_victim: { emoji: '😵', label: 'Sandwich Victims are Wallets that have been victims of Sandwich attackers 😵' },
        is_human: { emoji: '👨', label: 'Humans are wallets that are not bots 👨' },
    };

    return (
        <div className={`${styles.container} ${styles[layout]}`}>
            {Object.entries(labels).map(([key, { emoji, label }]) =>
                walletTypes[key as keyof WalletTypes] !== undefined && walletTypes[key as keyof WalletTypes] ? (
                    <div key={key} className={styles.emojiWrapper}>
                        <span className={styles.emoji}>{emoji}</span>
                        <div className={styles.tooltip}>{label}</div>
                    </div>
                ) : null
            )}
        </div>
    );
};
