import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Mode = 'SIMPLE' | 'PRO';

interface ModeState {
    currentMode: Mode;
}

const initialState: ModeState = {
    currentMode: 'SIMPLE', // Default to PRO mode
};

const modeSlice = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<Mode>) => {
            state.currentMode = action.payload;
        },
    },
});

export const { setMode } = modeSlice.actions;

export const selectMode = (state: { mode: ModeState }) => state.mode.currentMode;

export default modeSlice.reducer;
